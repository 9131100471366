export const Budget = [
  "Select budget",
  "Lower than $5K",
  "$5,000 - $10,000",
  "$10,000 - $20,000",
  "$20,000 - $50,000",
  "$50,000 - $100,000",
  ">$100,000"
];

export const STATE = {
  LOADING: "Loading",
  SUCCESS: "Success",
  ERROR: "Error"
};
