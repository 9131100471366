import { ClassNames } from "@emotion/react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { useInView } from "react-intersection-observer";
import AnimatedBox from "../../elements/AnimatedBox";
import AnimatedText from "../../elements/AnimatedText";
import Box from "../../elements/Box";
import Text from "../../elements/Text";
import colors from "../../styles/colors";
import ViewportWrapper from "../ViewportWrapper";

interface TestimonialsProps {
  image?: IGatsbyImageData;
  alt?: string;
  review: string;
  reviewer?: string;
}

const Testimonials = ({ image, alt, review, reviewer }: TestimonialsProps) => {
  const [ref, inView] = useInView({
    threshold: 0.6,
    triggerOnce: true
  });
  return (
    <ViewportWrapper ref={ref}>
      <Box margin={["1rem auto", "1rem auto", "2rem"]}>
        <Box
          backgroundColor={colors.black}
          padding={["3rem 0", "3rem 0", "3rem 0", "5rem 0", "5rem 0", "8rem 0"]}
          margin="auto"
          maxWidth={[
            "none",
            "none",
            "none",
            "none",
            "1200px",
            "1200px",
            "1440px"
          ]}
        >
          <Box
            maxWidth="1200px"
            margin="auto"
            width={["90%", "90%", "90%", "80%"]}
          >
            <Box display="flex">
              <AnimatedText
                lineHeight="42px"
                fontSize="80px"
                fontWeight="bold"
                color={colors.mediumLime}
                margin="0"
                doesAnimate={inView}
              >
                “
              </AnimatedText>
              <AnimatedText
                color={colors.white}
                fontWeight="bold"
                fontSize={["24px", "24px", "26px"]}
                as="h3"
                marginTop="1rem"
                doesAnimate={inView}
              >
                {review}
              </AnimatedText>
            </Box>
            <AnimatedBox doesAnimate={inView}>
              {image && alt && (
                <ClassNames>
                  {({ css }) => (
                    <GatsbyImage
                      image={image}
                      alt={alt ?? ""}
                      className={css(imageStyles)}
                      objectFit="contain"
                    />
                  )}
                </ClassNames>
              )}
            </AnimatedBox>
            <AnimatedText
              color={colors.white}
              margin="0 0 0 2rem"
              doesAnimate={inView}
            >
              {reviewer}
            </AnimatedText>
          </Box>
        </Box>
      </Box>
    </ViewportWrapper>
  );
};

const imageStyles = `
  margin-left: 2rem;
`;

export default Testimonials;
