import styled from "@emotion/styled";
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
  LayoutProps,
  layout,
  flexbox,
  FlexboxProps
} from "styled-system";

interface SelectProps
  extends FlexboxProps,
    SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps {
  hoverUnderline?: boolean;
  hoverNormal?: boolean;
  underline?: boolean;
}

const Select = styled.select<SelectProps>`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${flexbox}
  padding: 0.5rem;
  height: 2rem;
  outline: none;
  border: none;
`;

export default Select;
