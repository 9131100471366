import { StaticImage } from "gatsby-plugin-image";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import React from "react";
import App from "../components/App";
import FooterData, {
  NavigationInformation
} from "../components/Footer/footerData";
import ContactUsForm from "../components/ContactUsForm";
import Hero from "../components/Hero";
import Testimonials from "../components/Testimonials";
import Box from "../elements/Box";
import Text from "../elements/Text";
import SEO from "../components/SEO";
import Image from "../elements/Image";
import Location from "../images/ContactUs/pin.svg";
import Phone from "../images/ContactUs/phone.svg";
import Mail from "../images/ContactUs/mail.svg";

const GET_CONTACT_DETAILS = graphql`
  query GetContactDetails {
    allContentfulOfficeLocation {
      edges {
        node {
          contentful_id
          title
          address
          phone
          email
        }
      }
    }
  }
`;

const ContactUs = ({ location }: PageProps) => {
  const contactData = useStaticQuery(GET_CONTACT_DETAILS);
  const contactDetails: any[] =
    contactData?.allContentfulOfficeLocation?.edges?.map(
      (data: any) => data?.node
    );

  return (
    <App location={location}>
      <SEO
        url={location.href}
        title="Magento Enterprise Partner | Magento Company | acidgreen"
      />
      <Hero
        heading="Contact us"
        description="Give us a call or come visit us."
      ></Hero>
      <Box
        display="flex"
        flexDirection={["column", "column", "row"]}
        margin="1rem auto"
        maxWidth="1440px"
        width={["100%", "100%", "90%", "90%"]}
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignContent="center"
        >
          {contactDetails?.map((location: NavigationInformation, index) => (
            <Box
              maxWidth={["280px", "280px", "340px"]}
              padding="1rem 1.5rem"
              key={location?.contentful_id || index}
            >
              <Text fontWeight="bold" fontSize="18px" lineHeight="26px">
                {location.title}
              </Text>
              {location?.address && (
                <Box display="flex" alignItems="center">
                  <Image src={Location} alt="address" marginRight="0.5rem" />
                  <Text fontSize="18px" lineHeight="26px">
                    {location.address}
                  </Text>
                </Box>
              )}
              {location?.phone && (
                <Box display="flex" alignItems="center">
                  <Image src={Phone} alt="phone" marginRight="0.5rem" />
                  <Text fontSize="18px" lineHeight="26px">
                    {location.phone}
                  </Text>
                </Box>
              )}
              {location?.email && (
                <Box display="flex" alignItems="center">
                  <Image src={Mail} alt="email" marginRight="0.5rem" />
                  <Text fontSize="18px" lineHeight="26px">
                    {location.email}
                  </Text>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <ContactUsForm />
      <Testimonials
        review="acidgreen have turned around our delivery capability and restored trust from our internal stakeholders. The team are a pleasure to work with and show their experience and expertise every day."
        reviewer="Petbarn"
      />
    </App>
  );
};

export default ContactUs;
