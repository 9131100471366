import React from "react";
import Box from "../../elements/Box";
import Text from "../../elements/Text";
import colors from "../../styles/colors";

interface PopUpModalProps {
  text: string;
  children?: React.ReactNode;
  isActive: boolean;
}

const PopUpModal = ({ text, children, isActive }: PopUpModalProps) => {
  return (
    <Box
      position="absolute"
      padding={["3rem", "3rem", "5rem 3rem", "6rem 3rem"]}
      minWidth="230px"
      backgroundColor={colors.white}
      left="50%"
      top="8rem"
      transform="translateX(-50%)"
      display={isActive ? "flex" : "none"}
      alignItems="center"
      flexDirection="column"
      zIndex={1000}
    >
      {children}
      <Text
        fontSize={[3, 3, 4]}
        fontWeight="bold"
        textAlign="center"
        marginBottom="0"
      >
        {text}
      </Text>
    </Box>
  );
};

export default PopUpModal;
