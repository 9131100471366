import React from "react";
import Box from "../../elements/Box";
import Label from "../../elements/Label";
import Option from "../../elements/Option";
import Select from "../../elements/Select";

interface SelectOptionProps {
  options: string[];
  id: string;
  label: string;
  hasPlaceholder: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectOption = ({
  options,
  id,
  label,
  hasPlaceholder = false,
  onChange
}: SelectOptionProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Label htmlFor={id}>{label}</Label>
      <Select id={id} defaultValue={options[0]} onChange={onChange}>
        {options.map((option, index) => (
          <Option
            value={hasPlaceholder && index === 0 ? "" : option}
            key={index}
          >
            {option}
          </Option>
        ))}
      </Select>
    </Box>
  );
};

export default SelectOption;
