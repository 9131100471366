import React, { useState, useRef } from "react";
import Box from "../../elements/Box";
import Button from "../../elements/Button";
import Text from "../../elements/Text";
import colors from "../../styles/colors";
import { breakPoints } from "../../styles/responsive";
import { Budget } from "../../utils/constants";
import SelectOption from "../SelectOption";
import TextInput from "../TextInput";
import { FormStyled } from "./ContactUsFormStyled";
import { STATE } from "../../utils/constants";
import Mask from "../Mask";
import PopUpModal from "../PopUpModal";
import Image from "../../elements/Image";
import Check from "../../images/ContactUs/check-circle.svg";
import Cross from "../../images/ContactUs/cross.png";
import { useEffect } from "react";
import Span from "../../elements/Span";

declare global {
  interface Window {
    dataLayer: {
      pageCategory: string;
    }[];
  }
}

interface ContactFormType {
  name: string;
  businessName: string;
  email: string;
  number: number | null;
  website: string;
  budget: string;
  message: string;
}

type ContactFormTypeKeys = keyof ContactFormType;

const ContactFormInitialValues: ContactFormType = {
  name: "",
  businessName: "",
  email: "",
  number: null,
  website: "www.",
  budget: "",
  message: ""
};

const ContactUsForm = () => {
  const [form, setForm] = useState<ContactFormType>(ContactFormInitialValues);
  const [formState, setFormState] = useState("");

  const boxRef = useRef<HTMLDivElement | null>(null);

  const activePopUp = formState === STATE.SUCCESS || formState === STATE.ERROR;

  useEffect(() => {
    if (activePopUp) {
      window.scrollTo({
        top:
          (boxRef.current?.getBoundingClientRect()?.top ?? 0) + window.scrollY,
        behavior: "smooth"
      });
    }
  }, [formState]);

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const key = e.target.id;
    const value = e.target.value;
    if (
      (key === "number" && new RegExp("^[0-9]{0,10}$").test(value)) ||
      key !== "number"
    ) {
      setForm({
        ...form,
        [key]: value
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormState(STATE.LOADING);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      pageCategory: "form-submitted"
    });

    const formData = new FormData();

    for (const key in form) {
      const value = form[key as ContactFormTypeKeys];
      if (value !== undefined && value !== null) {
        formData.append(key, `${value}`);
      }
    }

    const response = await fetch(
      "https://getform.io/f/9dedd481-78b8-4806-83e7-b0af2c52f376",
      {
        method: "POST",
        body: formData
      }
    );

    if (response?.ok) {
      setForm(ContactFormInitialValues);
      setFormState(STATE.SUCCESS);
    } else {
      setFormState(STATE.ERROR);
    }
  };

  const loading = formState === STATE.LOADING;

  return (
    <Box
      backgroundColor={colors.lightGrey}
      padding={["3rem 0", "3rem 0", "5rem 0", "8rem 0"]}
      position="relative"
      ref={boxRef}
    >
      <Mask isActive={activePopUp} onClick={() => setFormState("")} />
      <PopUpModal
        isActive={activePopUp}
        text={
          formState === STATE.ERROR
            ? `Sorry there's been an issue! Please try again`
            : `Your message was sent successfully. 
      Thanks.`
        }
      >
        <Image
          src={formState === STATE.ERROR ? Cross : Check}
          alt={formState === STATE.ERROR ? "error" : "success"}
          width="75px"
        />
      </PopUpModal>

      <Box
        maxWidth={breakPoints.tablet}
        width={["90%", "90%", "80%"]}
        display="flex"
        flexDirection="column"
        margin="auto"
      >
        <Text fontSize={[4, 4, 5]} fontWeight="bold" textAlign="center">
          Get a free quote
        </Text>
        <FormStyled onSubmit={handleSubmit}>
          <Box
            display="grid"
            gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}
            gridColumnGap={[0, 0, "1rem"]}
          >
            <TextInput
              required
              id="name"
              type="text"
              label="Your name*"
              onChange={handleInputChange}
              value={form.name}
            />
            <TextInput
              required
              id="businessName"
              type="text"
              label="Business Name*"
              onChange={handleInputChange}
              value={form.businessName}
            />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}
            gridColumnGap={[0, 0, "1rem"]}
          >
            <TextInput
              required
              id="email"
              type="email"
              label="Email*"
              onChange={handleInputChange}
              value={form.email}
            />
            <TextInput
              required
              id="number"
              type="tel"
              pattern={`[0-9]{8,10}`}
              label="Phone Number*"
              onChange={handleInputChange}
              value={form.number}
            />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}
            gridColumnGap={[0, 0, "1rem"]}
          >
            <TextInput
              id="website"
              type="text"
              label="Website"
              onChange={handleInputChange}
              value={form.website}
            />
            <SelectOption
              label="Estimated budget"
              id="budget"
              options={Budget}
              hasPlaceholder
              onChange={handleInputChange}
            />
          </Box>
          <TextInput
            id="message"
            textarea
            type="text"
            label="Add Message"
            onChange={handleInputChange}
            value={form.message}
            height="8rem"
            placeholder={"Type in ..."}
          />
          <Button
            variant="primary"
            type="submit"
            display="flex"
            justifyContent="center"
            margin="1rem 0 0 auto"
            width={["100%", "100%", "auto"]}
            disabled={loading}
            backgroundColor={
              loading ? colors.transparentLime : colors.mediumLime
            }
          >
            {loading ? (
              <>
                Sending
                <Span color="rgba(0, 0, 0, 0.2)">.</Span>
                <Span delay="0.2s" color="rgba(0, 0, 0, 0.5)">
                  {`.`}
                </Span>
                <Span delay="0.3s">.</Span>
              </>
            ) : (
              "Send enquiry"
            )}
          </Button>
        </FormStyled>
      </Box>
    </Box>
  );
};

export default ContactUsForm;
