import styled from "@emotion/styled";
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
  LayoutProps,
  layout,
  flexbox,
  FlexboxProps
} from "styled-system";

interface OptionProps
  extends FlexboxProps,
    SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps {
  hoverUnderline?: boolean;
  hoverNormal?: boolean;
  underline?: boolean;
}

const Option = styled.option<OptionProps>`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${flexbox}
`;

export default Option;
